import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import SearchTemplate from "../../../../templates/search-results-template-new-homes";
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import { isTablet, isIPad13, isIOS13, isMobile, isMobileOnly, isDesktop, browserName } from 'react-device-detect';
import SeoResult from "../../../../components/seoResult";
import { milesToMeter, isAreaMatch, getLatLan } from "../../../../components/utils/property-search"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../../../components/utils/property-search"

import _ from "lodash";
import $ from "jquery"

const initConf = {
    hitsPerPage: 55,
    // getRankingInfo: true,
    removeWordsIfNoResults: 'lastWords'
}
const index_name = process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENT_INDEX_NAME;

class Search extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            query: "",
            min: "",
            max: "",
            bedrooms: "",
            building: "",
            searchType: "sales",
            department: "residential",
            insideBoundingBox: [],
            aroundLatLng: '',
            aroundRadius: 'all',
            getRankingInfo: false,
            popular: "",
            page: "",
            mapView: false,
            radius: 3,
            searchState: {},
            algoliaConfig: initConf,
            areaList: props.data?.allArea?.nodes,
            searchCounty: ""
        }
    }
    changeStateAndUrl = (searchState) => {
        let urls = [];
        var localQuery = localStorage.getItem('searchValue')
        if (localQuery !== null) {
            searchState.query = localQuery;
        }
        if (typeof searchState.query !== "undefined") {
            if (searchState.query !== "") {
                urls.push(`in-${searchState.query}`)
                this.setState({ query: searchState.query })
            }  
        } else {
            if (this.state.query !== "") {
                urls.push(`in-${this.state.query}`)
                this.setState({ query: this.state.query })
            }  
        }

      
        // create building URL
        if (typeof searchState.menu !== "undefined") {
            if (searchState.menu.building) {
                urls.push(`type-${searchState.menu.building}`)
                this.setState({ building: searchState.menu.building })
            } else {
                this.setState({ building: "" })
            }
            if (searchState.menu.accomadation_summary) {
                this.setState({ popular: searchState.menu.accomadation_summary })
            }
        } else if (this.state.building !== "") {
            urls.push(`type-${this.state.building}`)
        }
        // create building URL

        // create bedroom URL
        if (searchState.range && searchState.range.bedrooms) {
            let bedroom = searchState.range.bedrooms.min;
            let bedroom_max = searchState.range.bedrooms.max;
            if (bedroom) {
                urls.push(`${bedroom}-and-more-bedrooms`)
                this.setState({ bedrooms: bedroom })
            } else if (bedroom_max == 0) {
                urls.push(`studio`)
                this.setState({ bedrooms: bedroom_max })
            }
            else {
                this.setState({ bedrooms: "" })
            }
        } else if (this.state.bedrooms !== "") {
            urls.push(`${this.state.bedrooms}-and-more-bedrooms`)
        }
        // create bedroom URL

        // create Price min and Max URL
        if (typeof searchState.range !== "undefined") {
            // let price = searchState.range.price;
            let min = searchState.range.min_price?.min;
            let max = searchState.range.min_price?.max;
            if (min && max) {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } 
            }
        } else {
            let min = this.state.min;
            let max = this.state.max;
            if (min !== "" && max !== "") {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else {
                    this.setState({ max: "" })
                }
            }
        }

        // create radius URL   
        var isMatched = false
        if (!_.isEmpty(searchState.query) && this.state.radius != "") {
            let selectedArea = isAreaMatch(this.state.areaList, searchState.query)
            if (!_.isEmpty(selectedArea) && this.state.radius != "") {
                isMatched = true;
                // let algoliaConfig = { ...this.state.algoliaConfig }
                // algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                // algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                // algoliaConfig.getRankingInfo = true;
                // this.setState({ algoliaConfig });
                this.setState({ aroundLatLng: getLatLan(selectedArea) })
                this.setState({ aroundRadius: milesToMeter(this.state.radius) })
                this.setState({ getRankingInfo: true })
                urls.push(`radius-${this.state.radius}-miles`)
            } else {
                isMatched = false;
                this.setState({ algoliaConfig: initConf })
            }
        }
        // else if (!_.isEmpty(this.state.query) && this.state.radius != "") {
        //     let selectedArea = isAreaMatch(this.state.areaList, this.state.query)
        //     if (!_.isEmpty(selectedArea) && this.state.radius != "") {
        //         isMatched = true;
        //         let algoliaConfig = { ...this.state.algoliaConfig }
        //         algoliaConfig.aroundLatLng = getLatLan(selectedArea);
        //         algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
        //         this.setState({ algoliaConfig })
        //         urls.push(`radius-${this.state.radius}-miles`)
        //     } else {
        //         isMatched = false;
        //         this.setState({ algoliaConfig: initConf })
        //     }
        // } 
        else {
            isMatched = false;
            this.setState({ algoliaConfig: initConf })
        }
        if (localQuery !== null && isMatched) {
            searchState.query = '';
        } else {
            localStorage.removeItem('searchValue');
            localStorage.removeItem('searchValueFull');
            localStorage.removeItem('searchValueFullName');
        }


        // if (!_.isEmpty(this.state.radius)) {
        //     urls.push(`radius-${this.state.radius}-miles`)
        // } else {
        //     this.setState({ radius: "" })
        // }


        // Sort path
        // if (typeof searchState.sortBy !== "undefined") {
        //     if (searchState.sortBy) {
        //         if (searchState.sortBy === index_name + "_price_asc") {
        //             urls.push("sortby-price-asc")
        //         }
        //         if (searchState.sortBy === index_name + "_price_desc") {
        //             urls.push("sortby-price-desc")
        //         }
        //         if (searchState.sortBy === index_name + "_most_recent") {
        //             urls.push("sortby-most-recent")
        //         }
        //         if (searchState.sortBy === index_name + "_yield_desc") {
        //             urls.push("higest-yield")
        //         }
        //         if (searchState.sortBy === index_name + "_yield_asc") {
        //             urls.push("lowest-yield")
        //         }
        //     }
        // } else if (this.state.sortBy !== "") {
        //     urls.push(`${this.state.sortBy}`)
        // }
        // Sort path


        if (this.state.page !== "") {
            urls.push(`page-${this.state.page}`)
            searchState.page = this.state.page;
            this.setState({ page: this.state.page })
            setTimeout(() => {
                this.setState({ page: "" })
            }, 1000);

        } else if (this.state.page == "" && searchState.page !== "" && searchState.page > 1) {
            urls.push(`page-${searchState.page}`)
        }

        if (window) {
            this.setState({ searchCounty: window.localStorage.getItem('searchCounty') })
        }

        //this.setState({ searchState: searchState })
        let url = urls.join('/');
        let newRoute = '/property/new-homes/for-sale/' + url;
        navigate(newRoute.replace('//','/'),{ replace: true })
    }
    componentDidMount() {
        if (window)
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });

        const { params } = this.props;
        const searchParams = params['*'].split('/');

        
        if (window) {
            this.setState({ searchCounty: window.localStorage.setItem("searchCounty","") })
        }


        var queryParam = "";
        searchParams.forEach(param => {
            if (param.includes("in-") && (!param.includes("chain-"))) {
                // if(param.includes("in-")) {
                queryParam = param.substring(3);
                localStorage.setItem('searchValue', param.replace("in-",""));
                localStorage.setItem('searchValueFullName', hyphenToCaptilize(param.replace("in-","")));
                this.setState({ query: param.substring(3) })
            }
            if (param.includes("under-")) {
                this.setState({ max: param.substring(6) })
            }
            if ([param].indexOf("over-") >= 0) {
                this.setState({ min: param.substring(5) })
            }
            if (param.includes("-and-more-bedrooms")) {
                this.setState({ bedrooms: param.split("-")[0] })
            }
            if (param.includes("type-")) {
                this.setState({ building: param.split("-")[1] })
            }
            if (param.indexOf("page-") >= 0) {
                this.setState({ page: param.replace("page-", "") })
            }
            if (param.indexOf("sortby-") >= 0) {
                this.setState({ sortBy: param })
            }
            if (param.includes("radius-")) {
                let radius = param.split("-")[1];
                if (!_.isEmpty(queryParam) && radius != "") {
                    let selectedArea = isAreaMatch(this.state.areaList, queryParam)
                    if (!_.isEmpty(selectedArea) && radius != "") {
                        // let algoliaConfig = { ...this.state.algoliaConfig }
                        // algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                        // algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                        // algoliaConfig.getRankingInfo = true;
                        // this.setState({ radius, algoliaConfig }, () => {
                        //     this.changeStateAndUrl({ query: queryParam })
                        // })
                        this.setState({ aroundLatLng: getLatLan(selectedArea) })
                        this.setState({ aroundRadius: milesToMeter(this.state.radius) })
                        this.setState({ getRankingInfo: true })
                        this.setState({ radius: radius })
                        this.changeStateAndUrl({ aroundLatLng: getLatLan(selectedArea), aroundRadius: milesToMeter(this.state.radius), getRankingInfo: true })
                    } else {
                        this.setState({ algoliaConfig: initConf })
                    }
                } else {
                    this.setState({ algoliaConfig: initConf })
                }
            }
            if (param.includes("between-")) {
                this.setState({
                    min: param.split("-")[1],
                    max: param.split("-")[3]
                })
            }
        });

        if (sessionStorage.getItem('MainView') == 'Grid') {
            this.setState({ mapView: true })
        }


        $(document).mouseup(function (e) {
            var container = $(".dropdown-exp");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".dropdown-exp").removeClass("active");
            }
        });

    }
    // componentDidUpdate(prevProps) {
    //     // console.log("ccc1", prevProps, this.props.location)
    //     if (prevProps.location.pathname !== this.props.location.pathname) {

    //         $(window).on('popstate', function (event) {
    //             localStorage.removeItem('searchValue');
    //             window.location.href = prevProps.location.pathname;
    //         });
    //         // this.setState({ backenable: true }, () => {
    //         //     this.componentDidMount()
    //         // })
    //     }
    // }
    changeToMapUrl = () => {
        this.setState({ mapView: true })
        localStorage.setItem("map-hits", JSON.stringify([]));
        localStorage.setItem("map-bounds", '');
    }
    changeToListUrl = () => {
        this.setState({ mapView: false })
    }

    changeRadius = (radius) => { 
        if (radius != "") {
            var radius_query = this.state.searchState.query
            if (typeof radius_query == "undefined") {
                radius_query = this.state.query
            }
            this.setState({ radius, query: radius_query }, () => {
                this.changeStateAndUrl(this.state.searchState)
            })
        } else {
            this.setState({ radius: 3 }, () => { this.changeStateAndUrl(this.state.searchState) });
        }
    }



    render() {
        var search_area = "";
        var price_range = "", property_type = "", bedrooms = "", promotion = "", promotion_text = "", radius = "", radius_text = "", page = 1;
        var fullarea = typeof window !== 'undefined' && localStorage ? localStorage.getItem('searchValueFullName') : ''
        if (this.props.params["*"]) {
            search_area = fullarea ? "in " + fullarea : this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in " + this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "";

            price_range = this.props.params["*"].split("/").filter(c => (c.includes("over-") || c.includes("under-") || c.includes("between-")) && !c.includes("dover")).length > 0 ? this.props.params["*"].split("/").filter(c => (c.includes("over-") || c.includes("under-") || c.includes("between-")) && !c.includes("dover"))[0].split("-").map(c => isNaN(c) ? c : `£${parseInt(c).toLocaleString()}`).join(" ") : "";

            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1] : "";

            radius = this.props.params["*"].split("/").filter(c => c.includes("radius-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("radius-"))[0].split("radius-").map(c => _.upperFirst(c))[1] : "";

            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";


            promotion = this.props.params["*"].split("/").filter(c => c.includes("with-")).length > 0 ?
                this.props.params["*"].split("/").filter(c => c.includes("with-"))[0].split("-").join(" ") : '';


            page = this.props.params["*"].split("/").filter(c => c.includes("page-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("page-"))[0].split("page-").map(c => c)[1] : 1;

            property_type = property_type.replace("property", "properties")
            if(property_type && property_type.charAt(property_type.length-1) != "s") {
                property_type = property_type+'s'
            }

            promotion_text = _.startCase(_.toLower(promotion)).replace('With','with')

            radius_text = 'within '+radius.replace("-", " ")

        }

        var h1 = (price_range !== "" && property_type !== "" && bedrooms !== "") ?
        `New ${property_type.replace('-', ' ')} for Sale ${search_area} with more than ${bedrooms} Bedrooms ${price_range}` :
        (property_type !== "" && bedrooms !== "") ?
            `New ${property_type.replace('-', ' ')} for Sale ${search_area} with more than ${bedrooms} Bedrooms` : (price_range !== "" && bedrooms !== "") ?
                `New Homes for Sale ${search_area} with more than ${bedrooms} Bedrooms ${price_range}` : (price_range !== "" && property_type !== "") ?
                    `New ${property_type.replace('-', ' ')} for Sale ${search_area} ${price_range}` :
                    (bedrooms !== "") ?
                        `New Homes for Sale ${search_area} with more than ${bedrooms} Bedrooms` :
                        (property_type !== "") ?
                            `New ${property_type.replace('-', ' ')} for Sale ${search_area}` :
                            (price_range !== "") ?
                                `New Homes for Sale ${search_area} ${price_range}` : `New Homes for Sale ${search_area}`

        property_type = _.lowerFirst(property_type)


        var desc = search_area == 'in Blackwater, Newport, Isle of Wight' ? `Explore our latest collection of new homes for sale ${search_area}. Contact Pittis estate agents ${search_area} to find the right new homes for you.` : `Explore our range of new homes for sale ${search_area}. Contact our new homes experts to find the right new homes for sale in ${search_area}.`
        if(price_range !== "" && property_type !== "" && bedrooms !== "" && radius !== "") {
            desc = `Explore ${bedrooms} bedroom new ${property_type.replace('-', ' ')} for sale ${search_area} ${price_range} ${radius_text} available with Pittis. Contact one of our estate agents for assistance in finding your new homes ${search_area}.`
        }
        else if(property_type !== "" && bedrooms !== "" && radius !== "") {
            desc = `Find the latest new ${property_type.replace('-', ' ')} for sale ${search_area} with more than ${bedrooms} bedrooms ${radius_text}. Contact Pittis estate agents and letting agents ${search_area}, to arrange a viewing.`
        }
        else if(property_type !== "" && price_range !== "" && radius !== "") {
            desc = `Check out the latest collection of new ${property_type.replace('-', ' ')} for sale ${search_area} ${price_range} ${radius_text} with Pittis estate agents ${search_area}.`
        }
        else if(price_range !== "" && property_type !== "" && bedrooms !== "") {
            desc = `Check out the latest collection of new ${property_type.replace('-', ' ')} for sale ${search_area} with more than ${bedrooms} bedrooms ${price_range} with Pittis estate agents ${search_area}.`
        }
        else if(property_type !== "" && radius !== "") {
            desc = `Locate the best new ${property_type.replace('-', ' ')} for sale ${search_area} ${radius_text}. Book an appointment with Pittis estate agents ${search_area} to get assistance.`
        }
        else if(property_type !== "" && bedrooms !== "") {
            desc = `Find the latest new ${property_type.replace('-', ' ')} for sale ${search_area} with more than ${bedrooms} bedrooms. Contact Pittis estate agents and letting agents ${search_area}, to arrange a viewing.`
        }
        else if(price_range !== "" && radius !== "") {
            desc = `Identify the new homes for sale ${search_area} ${price_range} ${radius_text}. Book a viewing with Pittis estate agents ${search_area}.`
        }
        else if(bedrooms !== "" && radius !== "") {
            desc = `Explore new homes for sale ${search_area} with more than ${bedrooms} bedrooms ${radius_text} here. Contact our expert estate agents today to get assistance in finding the right new homes ${search_area}.`
        }
        else if(price_range !== "" && bedrooms !== "") {
            desc = `Explore the list of new homes for sale ${search_area} with more than ${bedrooms} bedrooms ${price_range} with Pittis and request a viewing for the new homes that interests you.`
        }
        else if(price_range !== "" && property_type !== "") {
            desc = `Check out the latest collection of new ${property_type.replace('-', ' ')} for sale ${search_area} ${price_range} with Pittis estate agents ${search_area}.`
        }
        else if(radius !== "") {
            desc = `Identify the best new homes for sale ${search_area} ${radius_text}. Book an appointment with Pittis estate agents ${search_area}.`
        }
        else if(price_range !== "") {
            desc = `Locate the right new homes for sale ${search_area} ${price_range} with Pittis estate agents. Book an appointment with one of our new homes experts ${search_area}.`
        }
        else if(bedrooms !== "") {
            desc = `Explore new homes for sale ${search_area} with more than ${bedrooms} bedrooms here. Contact our expert estate agents today to get assistance in finding the right new homes ${search_area}.`
        }
        else if(property_type !== "") {
            desc = `Looking for a new ${property_type.replace('-', ' ')} for sale ${search_area}? Pittis estate agents are here to help. `
        }

        var intro_copy = `Discover a wide range of ${_.lowerFirst(h1.replace("Properties","properties").replace("Sale","sale"))} ${promotion ? _.replace(promotion_text,new RegExp("And","g"),"and") : ''} ${radius ? radius_text : ''} with Pittis Estate Agents. To filter these results, use our filter above, or for more information about ${property_type ? `new `+property_type.replace("-"," ").toLowerCase() : 'new homes'} ${search_area}, please `
                        
        return (
            <>
                <SeoResult
                    title={`${h1} ${radius ? radius_text.replace("miles","Miles") : ''}`}
                    county={this.state.searchCounty}
                    description={desc}
                />
                <SearchTemplate
                    {...this.state}
                    changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    changeRadius={this.changeRadius}
                    location={this.props.location}
                    search_area={search_area}
                    page={page}
                    bedrooms={bedrooms}
                    property_type={property_type}
                    h1={`${h1} ${radius ? radius_text.replace("miles","Miles") : ''}`}
                    desc={`${intro_copy}`}
                // mapView={sessionStorage.getItem('ResultsView') == 'gridview'}
                />
            </>
        )
    }
}

export default Search;


export const pageQuery = graphql`
query {
    allArea {
      nodes {
        id
        name
        latitude
        longitude
        districtCountyTown
      }
    }
  }
`